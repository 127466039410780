<template>
  <div>
    <img src="../assets/logo.webp" alt="Logo" class="logo" v-on:click="goHome"/>
  </div>
</template>

<script>
export default {
  name: "header",
  methods:{
    goHome(){
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>
.logo {
  max-width: 37vw;
  position: relative;
  left: 32%;
}


@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
}

@media only screen and (max-width: 600px) {
  .logo {
    max-width: 45vh;
    position: relative;
    left: 0;
  }
}

</style>