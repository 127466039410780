<template>
<div>
  <v-card
      elevation="0"
      class="footer"
  >
    <v-row>
      <v-col>
        <span class="link" v-on:click="redirect('tc')" ><u>Terms and Conditions</u></span>
      </v-col>
      <v-col>
        <span class="link" v-on:click="redirect('disclosure')"><u>Disclosure Policy</u></span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span class="link" v-on:click="redirect('aboutus')"><u>About Us</u></span>
      </v-col>
      <v-col>
        <span class="link" v-on:click="redirect('refund')"><u>Refund Policy</u></span>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <span class="link" v-on:click="redirect('')" style="text-decoration: none;">&copy; shopactreg.in</span>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <a href="mailto:contact.shopactreg@gmail.com" class="link">contact.shopactreg@gmail.com</a>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <span >Contact Us at 9689645226</span>
      <v-spacer></v-spacer>
    </v-row>
  </v-card>
  <br>
  <br>
</div>
</template>

<script>
export default {
  name: "footer",
  methods:{
    redirect(name){
      window.scrollTo(0, 0);
      this.$router.push("/" + name);
    }
  }
}
</script>

<style scoped>

.footer{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.link{
  color: black;
  cursor:pointer;
}

</style>